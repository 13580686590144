<template>
  <div class="list_body">
    <div :style="{ minHeight: '5vh' }">位置:一级页面/二级页面</div>
    <div :style="{ padding: '15px', background: '#fff', minHeight: '5vh', display: 'flex' }">
      <a-form-model layout="inline" @submit="searchSubmit">
        <a-form-model-item label="新闻标题:">
          <div style="background-color: red; width: 20px; higth: 10px"></div>
          <a-input v-model="searchForm.title" placeholder="请输入" :style="{ display: 'inline' }" :allowClear="true" />
        </a-form-model-item>
        <a-form-model-item label="状态:">
          <a-select value="请选择" style="width: 120px" v-model="searchForm.status" :allowClear="true">
            <a-select-option :value="newStatus.name" v-for="newStatus in newStatuss" :key="newStatus.id"> {{ newStatus.name }} </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="所属栏目:">
          <a-cascader :options="options" placeholder="请选择" @change="onChange" expand-trigger="hover" />
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="primary" html-type="submit">查询</a-button>
        </a-form-model-item>
      </a-form-model>
    </div>
    <div :style="{ padding: '10px' }"></div>
    <div :style="{ padding: '24px', background: '#fff', minHeight: '90vh' }">
      <!-- 新增 -->
      <a-space>
        <a-button type="primary" @click="save"> 新增 </a-button>
        <a-button type="primary" @click="shelve"> 批量上架 </a-button>
        <a-button type="primary" @click="unShelve"> 批量下架 </a-button>
        <a-button type="primary" @click="del"> 批量删除 </a-button>
      </a-space>
      <!-- 表格 -->
      <a-table
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :columns="columns"
        :data-source="data"
        @change="handleTableChange"
        :pagination="pagination"
        :row-key="(record) => record.id"
      >
        <span slot="num" slot-scope="text, record, index"> {{ (pagination.current - 1) * pagination.pageSize + index + 1 }}</span>
        <span slot="title" slot-scope="text">{{ text }}</span>
        <span slot="section_name" slot-scope="text">{{ text }}</span>
        <span slot="updated" slot-scope="text">{{ text }}</span>
        <span slot="publish_date" slot-scope="text">{{ text }}</span>
        <span slot="un_publish_date" slot-scope="text">{{ text }}</span>
        <span slot="status" slot-scope="text">{{ text }}</span>
        <span slot="action" slot-scope="text, record">
          <span style="color: #2e71fe; font-size: 10px; font-weight: 30px" @click="update(record)">编辑</span>
          <a-divider type="vertical" />
          <span style="color: #2e71fe; font-size: 10px; font-weight: 30px" @click="lookNews(record)">查看</span>
          <a-divider type="vertical" />
          <span style="color: #2e71fe; font-size: 10px; font-weight: 30px" @click="shelve(record)" v-if="record.status == '下架'"> 上架 </span>
          <span style="color: #2e71fe; font-size: 10px; font-weight: 30px" @click="shelve(record)" v-if="record.status == '未上架'"> 上架 </span>
          <span style="color: #2e71fe; font-size: 10px; font-weight: 30px" @click="unShelve(record)" v-if="record.status == '上架'"> 下架 </span>
          <a-divider type="vertical" />
          <span style="color: #2e71fe; font-size: 10px; font-weight: 30px" @click="del(record)"> 删除 </span>
        </span>
      </a-table>
    </div>
  </div>
</template>

<script>
/**表头信息 */
const columns = [
  {
    title: '序号',
    dataIndex: 'index',
    key: 'index',
    scopedSlots: { customRender: 'num' }, //customRender值不能叫title
  },
  {
    title: '新闻标题',
    dataIndex: 'title',
    key: 'title',
    width: 250,
    scopedSlots: { customRender: 'name' },
  },
  {
    title: '所属栏目',
    dataIndex: 'sectionName',
    key: 'sectionName',
    scopedSlots: { customRender: 'sectionName' },
  },
  {
    title: '更新时间',
    key: 'updated',
    dataIndex: 'updated',
    scopedSlots: { customRender: 'updated' },
  },
  {
    title: '上架时间',
    key: 'publishDate',
    dataIndex: 'publishDate',
    scopedSlots: { customRender: 'publish_date' },
  },
  {
    title: '下架时间',
    key: 'unPublishDate',
    dataIndex: 'unPublishDate',
    scopedSlots: { customRender: 'un_publish_date' },
  },
  {
    title: '状态',
    dataIndex: 'status',
    key: 'status',
    scopedSlots: { customRender: 'status' },
  },
  {
    title: '操作',
    key: 'action',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' },
  },
];

import DateUtils from '@/common/DateUtils';
const data = [];
export default {
  data() {
    return {
      newStatuss: [
        { id: 1, name: '上架' },
        { id: 2, name: '未上架' },
        { id: 3, name: '下架' },
      ],
      options: [],
      selectedRowKeys: [], // Check here to configure the default column
      sections: [],
      data, //表格数据，从服务端中获取
      columns, //表头
      searchForm: { title: '', status: '', sectionId: null },
      pagination: { pageSize: 10, total: 100, current: 1, showSizeChanger: true }, //分页参数
    };
  },
  created() {
    this.loadList();
  },
  methods: {
    /**读取文章列表 */
    async loadList() {
      //调用服务端接口获取文章列表
      let result = await this.axios({
        method: 'post',
        url: `news/search/${this.pagination.current}/${this.pagination.pageSize}`,
        data: this.searchForm,
      });
      let section = await this.axios({
        method: 'get',
        url: `section/getAll`,
        // data: that.searchForm1,
      });
      this.sections = section.data.data;
      var datum = result.data.data;
      this.pagination.total = result.data.total; //总数
      for (let d of datum) {
        if (d.updated == null) {
          d['updated'] = '--';
        }
        if (d.publishDate == null) {
          d['publishDate'] = '--';
        }
        if (d.unPublishDate == null) {
          d['unPublishDate'] = '--';
        }
        for (let s of section.data.data) {
          if (d.sectionId == s.id) {
            d['sectionName'] = s.title;
          }
        }
      }
      this.options = [];
      for (let s of section.data.data) {
        if (s.parentId == '0') {
          let obj = {};
          obj.value = s.id;
          obj.label = s.title;
          obj.children = [];
          for (let o of section.data.data) {
            if (o.parentId == obj.value) {
              let obj2 = {};
              obj2.value = o.id;
              obj2.label = o.title;
              obj.children.push(obj2);
            }
          }
          this.options.push(obj);
        }
      }
      this.data = datum;
    },
    /**确认查询 */
    searchSubmit(e) {
      this.loadList(); //重新读取文章列表
      e.preventDefault();
    },
    /**分页 */
    handleTableChange(pagination, filters, sorter) {
      this.pagination = pagination; //保存分页信息
      this.loadList(); //重新读取文章列表
    },
    /**所属栏目 */
    onChange(value) {
      this.searchForm.sectionId = value[1];
    },
    /**多选 */
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    /**查看新闻 */
    lookNews(obj) {
      //跳转至查看界面
      this.$router.push({
        name: 'lookNews',
        params: {
          id: obj.id, //携带文章编号
        },
      });
    },
    /**修改文章 */
    update(obj) {
      //跳转至修改界面
      this.$router.push({
        name: 'newsUpdate',
        params: {
          id: obj.id, //携带文章编号
        },
      });
    },
    save() {
      this.$router.push({
        name: 'newsAdd',
      });
    },
    /**删除文章 */
    del(record) {
      var that = this;
      //删除提示
      this.$confirm({
        title: '提示',
        content: '是否确定删除',
        async onOk() {
          //确定删除
          try {
            let newIds = that.selectedRowKeys;
            if (record.id != null) {
              newIds = [record.id];
            }
            let result = await that.axios.delete(`news/delete?newIds=${newIds}`);
            that.$message.success('删除成功');
            that.loadList();
          } catch (error) {
            that.$message.error('删除失败');
          }
        },
        onCancel() {}, //点击取消按钮后
      });
    },
    /**批量上架 */
    async shelve(record) {
      let newIds = this.selectedRowKeys;
      if (record.id != null) {
        newIds = [record.id];
      }
      try {
        let result = await this.axios.get(`news/shelve?newIds=${newIds}`);
        this.loadList();
        this.$message.success('修改成功');
      } catch (error) {
        this.$message.error('修改失败');
      }
    },
    /**批量下架 */
    async unShelve(record) {
      let newIds = this.selectedRowKeys;
      if (record.id != null) {
        newIds = [record.id];
      }
      try {
        let result = await this.axios.get(`news/unShelve?newIds=${newIds}`);
        this.loadList();
        this.$message.success('修改成功');
      } catch (error) {
        this.$message.error('修改失败');
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">
.ant-table-title {
  display: none;
}
</style>
